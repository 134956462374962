import { inject }                from 'aurelia-framework';
import { Clause }                from 'modules/management/models/clause';
import { ClausesRepository }     from 'modules/management/clauses/services/repository';
import { RegulationsRepository } from 'modules/management/regulations/services/repository';

@inject(ClausesRepository, RegulationsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {Clause}
     */
    model() {
        let model = new Clause();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Constructor
     *
     * @param clausesRepository
     * @param regulationsRepository
     */
    constructor(clausesRepository, regulationsRepository) {
        this.clausesRepository     = clausesRepository;
        this.regulationsRepository = regulationsRepository;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.parent = {
            type:       'text',
            key:        'parent',
            label:      'form.field.group',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.editable = {
            type:       'text',
            key:        'editable',
            label:      'form.field.contains-editable-text?',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.status = {
            type:       'text',
            key:        'status',
            label:      'form.field.status',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.parent],
            [this.name],
            [this.editable, this.status],
        ];
    }

    /**
     * Returns the global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.clauses.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
