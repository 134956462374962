import { bindable, inject }    from 'aurelia-framework';
import { BaseListViewModel }   from 'base-list-view-model';
import { LiftTypesRepository } from 'modules/management/lift-types/services/repository';
import { AppContainer }        from 'resources/services/app-container';

@inject(AppContainer, LiftTypesRepository)
export class ListLiftTypes extends BaseListViewModel {

    listingId = 'management-lift-types-listing';

    @bindable headerTitle    = 'listing.management.lift-types';
    @bindable newRecordRoute = 'management.lift-types.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository     = repository;
        this.newRecordRoute = appContainer.authenticatedUser.can(['management.lift-types.manage', 'management.lift-types.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.lift-types.manage',
            'management.lift-types.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.management.lift-type',
            repository:      this.repository,
            show:            {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['management.lift-types.manage', 'management.lift-types.view']),
            },
            edit:            {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['management.lift-types.manage', 'management.lift-types.edit']),
            },
            destroy:         {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['management.lift-types.manage', 'management.lift-types.delete']),
            },
            destroySelected: this.appContainer.authenticatedUser.can(['management.lift-types.manage', 'management.lift-types.delete']),
            actions:         [],
            options:         [],
            buttons:         [],
            selectable:      true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'lift_types.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
