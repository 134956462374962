import { bindable, inject }      from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { BaseListViewModel }     from 'base-list-view-model';
import { ClausesRepository }     from 'modules/management/clauses/services/repository';
import { ClausesTreeRepository } from 'modules/management/clauses-tree/services/repository';
import { DialogService }         from 'aurelia-dialog';
import { ImportClauses }         from 'modules/management/clauses/import/index';

@inject(AppContainer, ClausesRepository, ClausesTreeRepository, DialogService)
export class ListClauses extends BaseListViewModel {

    listingId = 'management-clauses-listing';

    @bindable headerTitle    = 'listing.management.clauses';
    @bindable newRecordRoute = 'management.clauses.create';
    @bindable repository;
    @bindable datatable;

    treeOptions = {
        id:       'management-clauses-listing-tree',
        click:    this.treeNodeClicked.bind(this),
        activate: this.treeNodeActivated.bind(this),
    };

    filterModel = {
        parent_id: null,
    };

    displayTree = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param treeRepository
     * @param dialogService
     */
    constructor(appContainer, repository, treeRepository, dialogService) {
        super(appContainer);

        this.repository     = repository;
        this.dialogService  = dialogService;
        this.storage        = appContainer.localStorage;
        this.newRecordRoute = this.newRecordRoute;
        this.importClauses  = this.doImportClauses.bind(this);

        this.treeOptions.repository = { source: treeRepository, method: 'all' };

        if (! appContainer.authenticatedUser.can(['management.clauses.manage', 'management.clauses.create'])) {
            this.newRecordRoute = null;
            this.importClauses  = null;
        }
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.clauses.manage',
            'management.clauses.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.setTreeInitialState();
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.management.clause',
            repository: this.repository,
            show:       {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['management.clauses.manage', 'management.clauses.view']),
            },
            edit:       {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['management.clauses.manage', 'management.clauses.edit']),
            },
            destroy:    {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['management.clauses.manage', 'management.clauses.delete']),
            },
            destroyed:  (row) => this.reloadFancyTree(),
            actions:    [],
            options:    [],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'name',
                    name:  'clauses.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

    /**
     * Handles tree node click event
     *
     * @param event
     * @param data
     */
    treeNodeClicked(event, data) {
        let activeNode = data.tree.getActiveNode();

        if (activeNode === data.node) {
            activeNode.setFocus(false);
            activeNode.setActive(false);

            this.filterModel.parent_id = null;

            this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', { listingId: this.listingId });

            return false;
        }
    }

    /**
     * Handles tree node activate event
     *
     * @param event
     * @param data
     */
    treeNodeActivated(event, data) {
        this.filterModel.parent_id = data.node.key;

        this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', { listingId: this.listingId });
    }

    /**
     * Reloads the fancy tree
     */
    reloadFancyTree() {
        this.appContainer.eventAggregator.publish('reload-fancy-tree', { id: this.treeOptions.id });
    }

    /**
     * Toggles the tree visibility
     */
    toggleTree() {
        this.treeOptions.activatedKey = this.filterModel.parent_id;

        this.displayTree = !this.displayTree;

        this.storage.set(this.treeOptions.id, JSON.stringify({ display: this.displayTree }));
    }

    /**
     * Sets the tree's initial visibility state
     */
    setTreeInitialState() {
        let settings = JSON.parse(this.storage.get(this.treeOptions.id));

        if (settings) {
            this.displayTree = typeof settings.display === 'undefined' ? true : settings.display;
        }
    }

    /**
     * Opens the clauses import modal
     */
    doImportClauses() {
        this.fabActionMenu.toggleState();

        this.dialogService
            .open({ viewModel: ImportClauses })
            .whenClosed((response) => {
                if (! response.wasCancelled) {
                    this.datatable.instance.reload();
                    this.displayTree && this.reloadFancyTree();
                }
            });
    }

}
