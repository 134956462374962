import { inject }                from 'aurelia-framework';
import { Clause }                from 'modules/management/models/clause';
import { BooleanStatus }         from 'modules/administration/models/boolean-status';
import { ClausesRepository }     from 'modules/management/clauses/services/repository';
import { ClausesTreeRepository } from 'modules/management/clauses-tree/services/repository';
import { DialogService }         from 'aurelia-dialog';
import { TreeModal }             from 'modules/administration/tree-modal/index';

@inject(ClausesRepository, ClausesTreeRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{status_id: number}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {Clause}
     */
    model() {
        let model = new Clause();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Constructor
     *
     * @param clausesRepository
     * @param clausesTreeRepository
     * @param dialogService
     */
    constructor(clausesRepository, clausesTreeRepository, dialogService) {
        this.clausesRepository     = clausesRepository;
        this.clausesTreeRepository = clausesTreeRepository;
        this.dialogService         = dialogService;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.parent_id = {
            type:         'select2',
            key:          'parent_id',
            label:        'form.field.group',
            size:         12,
            required:     false,
            options:      [],
            remoteSource: this.clausesRepository.active.bind(this.clausesRepository),
            inputGroup:   {
                position: 'right',
                button:   {
                    title:   'form.button.open-clauses-tree',
                    action:  () => this.openTreeModal(viewModel),
                    icon:    { class: 'icon-tree5' },
                    visible: true,
                },
            },
        };

        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.designation',
            size:  12,
        };

        this.editable_id = {
            type:  'boolean-options-select',
            key:   'editable_id',
            label: 'form.field.contains-editable-text?',
            size:  4,
        };

        this.status_id = {
            type:  'boolean-statuses-select',
            key:   'status_id',
            label: 'form.field.status',
            size:  4,
        };

        return [
            [this.parent_id],
            [this.name],
            [this.editable_id, this.status_id],
        ];
    }

    /**
     * Returns the global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.clauses.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.submitAndContinueButton = {
            type:       'submit',
            label:      'form.button.save-and-continue',
            action:     () => viewModel.submit(true),
            hidden:     viewModel.creating !== true,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
                this.submitAndContinueButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Opens the clauses tree modal
     */
    openTreeModal(viewModel) {
        this.dialogService.open({
            viewModel: TreeModal,
            model:     {
                repository: {
                    method: 'active',
                    source: this.clausesTreeRepository,
                },
                onClick: (event, data) => viewModel.model.parent_id = Number(data.node.key),
            },
        });
    }

}
