import { BaseRepository } from 'modules/core/services/base-repository';

export class ClausesRepository extends BaseRepository {

    baseUrl = 'management/clauses';

    /**
     * Returns a record's regulations
     *
     * @param id
     *
     * @returns {*}
     */
    regulations(id) {
        return this.httpClient.get(`${this.baseUrl}/${id}/regulations`);
    }

    /**
     * Imports the data from the submitted file
     *
     * @param data
     *
     * @returns {*}
     */
    import(data) {
        return this.httpClient.post(`${this.baseUrl}/import`, data);
    }

}
