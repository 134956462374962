import { BaseRepository } from 'modules/core/services/base-repository';

export class InspectionPeriodicitiesRepository extends BaseRepository {

    baseUrl = 'management/inspection-periodicities';

    /**
     * Returns the inspection periodicity for a giver lift type and building type
     *
     * @param liftTypeId
     * @param buildingTypeId
     *
     * @returns {*}
     */
    forLiftTypeAndBuildingType(liftTypeId, buildingTypeId) {
        return this.httpClient.get(`${this.baseUrl}/${liftTypeId}/${buildingTypeId}`);
    }

}
