import { bindable, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseFormViewModel }          from 'base-form-view-model';
import { LiftTypesRepository }        from 'modules/management/lift-types/services/repository';
import { FormSchema }                 from 'modules/management/lift-types/view/form-schema';

@inject(AppContainer, LiftTypesRepository, FormSchema)
export class ViewLiftType extends BaseFormViewModel {

    @bindable headerTitle     = 'form.title.view-record';
    @bindable editRecordRoute = 'management.lift-types.edit';
    @bindable formId          = 'management.lift-types.view-form';

    alert  = {};
    model  = {};
    schema = {};

    viewing = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository      = repository;
        this.formSchema      = formSchema;
        this.editRecordRoute = appContainer.authenticatedUser.can(['management.lift-types.manage', 'management.lift-types.edit']) ? this.editRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/lift-types/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.lift-types.manage',
            'management.lift-types.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema              = this.formSchema.schema(this);
            this.globalButtonsSchema = this.formSchema.globalButtonsSchema(this);
        });
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({id}) {
        return Promise.all([
            this.repository.find(id).then((response) => {
                this.initialModel.assign(response);
                this.model.assign(response);

                return response;
            }),
        ]);
    }

}
