import { inject }                    from 'aurelia-framework';
import { LiftType }                  from 'modules/management/models/lift-type';
import { BooleanStatus }             from 'modules/administration/models/boolean-status';
import { BooleanStatusesRepository } from 'modules/administration/boolean-statuses/services/repository';
import { EquipmentTypesRepository }  from 'modules/management/equipment-types/services/repository';
import { RegulationsRepository }     from 'modules/management/regulations/services/repository';

@inject(RegulationsRepository, BooleanStatusesRepository, EquipmentTypesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{status_id: number}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param regulationsRepository
     * @param booleanStatusesRepository
     * @param equipmentTypesRepository
     */
    constructor(regulationsRepository, booleanStatusesRepository, equipmentTypesRepository) {
        this.regulationsRepository     = regulationsRepository;
        this.booleanStatusesRepository = booleanStatusesRepository;
        this.equipmentTypesRepository  = equipmentTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LiftType}
     */
    model() {
        let model = new LiftType();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       8,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.equipment-type',
            size:         4,
            required:     false,
            options:      [],
            remoteSource: this.equipmentTypesRepository.active.bind(this.equipmentTypesRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.applicable_regulations = {
            type:         'duallistbox',
            key:          'applicable_regulations',
            label:        'form.field.applicable-regulation',
            size:         12,
            required:     false,
            options:      [],
            remoteSource: this.regulationsRepository.all.bind(this.regulationsRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            required:     false,
            options:      [],
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.name, this.type_id],
            [this.applicable_regulations],
            [this.status_id],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     (event) => viewModel.redirectToRoute('management.lift-types.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
