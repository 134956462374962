import { bindable, inject }        from 'aurelia-framework';
import { AppContainer }            from 'resources/services/app-container';
import { BaseListViewModel }       from 'base-list-view-model';
import { BuildingTypesRepository } from 'modules/management/building-types/services/repository';

@inject(AppContainer, BuildingTypesRepository)
export class ListBuildingTypes extends BaseListViewModel {

    listingId = 'management-building-types-listing';

    @bindable headerTitle    = 'listing.management.building-types';
    @bindable newRecordRoute = 'management.building-types.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository     = repository;
        this.newRecordRoute = appContainer.authenticatedUser.can(['management.building-types.manage', 'management.building-types.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.building-types.manage',
            'management.building-types.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.management.building-type',
            repository: this.repository,
            show:       {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['management.building-types.manage', 'management.building-types.view']),
            },
            edit:       {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['management.building-types.manage', 'management.building-types.edit']),
            },
            destroy:    {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['management.building-types.manage', 'management.building-types.delete']),
            },
            actions:    [],
            options:    [],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'name',
                    name:  'building_types.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
