import { inject }                                 from 'aurelia-framework';
import { ClauseApplicableRegulation }             from 'modules/management/models/clause-applicable-regulation';
import { NonconformityClassificationsRepository } from 'modules/management/nonconformity-classifications/services/repository';
import { RegulationsRepository }                  from 'modules/management/regulations/services/repository';

@inject(NonconformityClassificationsRepository, RegulationsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param classificationsRepository
     * @param regulationsRepository
     */
    constructor(classificationsRepository, regulationsRepository) {
        this.classificationsRepository = classificationsRepository;
        this.regulationsRepository     = regulationsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ClauseApplicableRegulation}
     */
    model() {
        let model = new ClauseApplicableRegulation();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.regulations = {
            type:           'multiselect-native',
            key:            'regulations',
            label:          'form.field.applicable-regulation',
            size:           4,
            options:        [],
            remoteSource:   this.regulationsRepository.active.bind(this.regulationsRepository),
            processResults: (row) => {
                row.name = row.description;

                return row;
            },
        };

        this.point = {
            type:  'text',
            key:   'point',
            label: 'form.field.article/point',
            size:  4,
        };

        this.clause_types = {
            type:         'multiselect-native',
            key:          'clause_types',
            label:        'form.field.clause-types',
            size:         4,
            options:      [],
            remoteSource: this.classificationsRepository.active.bind(this.classificationsRepository),
        };

        this.closeButton = {
            type:       'submit',
            label:      'form.button.close',
            action:     () => viewModel.creationSchemaVisible = false,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-cross',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal pull-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:       'buttons',
            attributes: {
                class: 'form-wizard-actions ml-auto ml-2 mr-2',
            },
            actions:    [
                this.closeButton,
                this.submitButton,
            ],
        };

        return [
            [this.regulations, this.point, this.clause_types],
            [this.buttons],
        ];
    }

}
