import { inject }                from 'aurelia-framework';
import { ClauseFilesRepository } from 'modules/management/clauses/services/files-repository';
import { ClauseImport }          from 'modules/management/models/clause-import';

@inject(ClauseFilesRepository)
export class FormSchema {

    /**
     * Returns a new instance of the model
     *
     * @returns {ClauseImport}
     */
    model() {
        let model = new ClauseImport();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Constructor
     *
     * @param filesRepository
     */
    constructor(filesRepository) {
        this.filesRepository = filesRepository;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.file_id = {
            type:  'file-dropzone',
            key:   'file_id',
            label: 'form.field.excel-file',
            size:  12,
            url:   this.filesRepository.uploadUri(),
        };

        return [
            [this.file_id],
        ];
    }

    /**
     * Returns the tempate form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    templateSchema(viewModel) {
        this.downloadTemplateButton = {
            type:       'button',
            label:      'form.button.download-template-file',
            action:     () => viewModel.downloadTemplate(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-file-excel',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.downloadTemplateButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.cancelButton = {
            type:       'button',
            label:      'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
