import { inject }                  from 'aurelia-framework';
import { InspectionPeriodicity }   from 'modules/management/models/inspection-periodicity';
import { BuildingTypesRepository } from 'modules/management/building-types/services/repository';

@inject(BuildingTypesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param buildingTypesRepository
     */
    constructor(buildingTypesRepository) {
        this.buildingTypesRepository = buildingTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {InspectionPeriodicity}
     */
    model() {
        let model = new InspectionPeriodicity();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.building_types = {
            type:         'multiselect-native',
            key:          'building_types',
            label:        'form.field.building-type',
            size:         4,
            options:      [],
            remoteSource: this.buildingTypesRepository.active.bind(this.buildingTypesRepository),
        };

        this.periodicity = {
            type:  'number',
            key:   'periodicity',
            label: 'form.field.periodicity(years)',
            size:  4,
        };

        this.closeButton = {
            type:       'submit',
            label:      'form.button.close',
            action:     () => viewModel.creationSchemaVisible = false,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-cross',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal pull-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:       'buttons',
            attributes: {
                class: 'form-wizard-actions ml-auto ml-2 mr-2',
            },
            actions:    [
                this.closeButton,
                this.submitButton,
            ],
        };

        return [
            [this.building_types, this.periodicity],
            [this.buttons],
        ];
    }

}
