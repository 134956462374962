import { BaseRepository } from 'modules/core/services/base-repository';

export class LiftTypesRepository extends BaseRepository {

    baseUrl = 'management/lift-types';

    /**
     * Fetches lift type applicable regulations from server
     *
     * @param id
     *
     * @returns {*}
     */
    applicableRegulations(id) {
        return this.httpClient.get(`${this.baseUrl}/${id}/applicable-regulations`);
    }

}
