import { bindable, bindingMode, inject }         from 'aurelia-framework';
import { AppContainer }                          from 'resources/services/app-container';
import { BaseViewModel }                         from 'base-view-model';
import { ClauseApplicableRegulationsRepository } from 'modules/management/clauses/applicable-regulations/services/repository';
import { FormSchema }                            from 'modules/management/clauses/applicable-regulations/form-schema';

@inject(AppContainer, FormSchema, ClauseApplicableRegulationsRepository)
export class ClauseApplicableRegulations extends BaseViewModel {

    listingId = 'management-clauses-applicable-regulations-listing';
    formId    = 'management-clauses-applicable-regulations-creation-form';

    @bindable({ defaultBindingMode: bindingMode.twoWay }) clause;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) readonly = false;

    repository;
    datatable;
    creationSchemaVisible = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, formSchema, repository) {
        super(appContainer);

        this.formSchema = formSchema;
        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.clauses.manage',
            'management.clauses.view',
            'management.clauses.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    bind() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
        this.schema       = this.formSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.management.applicable-regulation',
            repository:      {
                search:          (criteria) => this.repository.search(this.clause.id, criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            destroy:         !this.readonly,
            destroySelected: !this.readonly,
            actions:         [],
            options:         [],
            buttons:         [
                {
                    label:   'form.button.create-new',
                    icon:    'icon-plus3',
                    color:   'success',
                    visible: !this.readonly,
                    action:  () => this.creationSchemaVisible = true,
                },
            ],
            selectable:      true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'regulation',
                    name:  'regulations.description',
                    title: 'form.field.applicable-regulation',
                },
                {
                    data:  'point',
                    name:  'regulation_clauses.point',
                    title: 'form.field.article/point',
                },
                {
                    data:  'clause_type',
                    name:  'clause_nonconformity_classifications.name',
                    title: 'form.field.clause-type',
                },
            ],
        };
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.repository
            .create(this.clause.id, this.model)
            .then(response => this.afterSubmitCallback(response))
            .catch(console.log.bind(console));
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);
            this.datatable.instance.reload();
            this.resetForm();
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues()
            .then(() => {
                // publishes `form-reseted` event
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);

                if (nullifyAlert) {
                    this.alert = null;
                }
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
            this.model.assign(this.initialModel);

            resolve(true);
            reject(new Error('Error'));
        });
    }

}
