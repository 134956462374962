import { bindable, bindingMode, inject }             from 'aurelia-framework';
import { AppContainer }                              from 'resources/services/app-container';
import { BaseViewModel }                             from 'base-view-model';
import { FormSchema }                                from 'modules/management/lift-types/inspection-periodicities/form-schema';
import { LiftTypeInspectionPeriodicitiesRepository } from 'modules/management/lift-types/inspection-periodicities/services/repository';

@inject(AppContainer, FormSchema, LiftTypeInspectionPeriodicitiesRepository)
export class LiftTypeInspectionPeriodicities extends BaseViewModel {

    listingId = 'management-lift-types-inspection-periodicities-listing';
    formId    = 'management-lift-types-inspection-periodicities-creation-form';

    @bindable({defaultBindingMode: bindingMode.twoWay}) liftType;
    @bindable({defaultBindingMode: bindingMode.twoWay}) readonly = false;

    repository;
    datatable;
    creationSchemaVisible = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, formSchema, repository) {
        super(appContainer);

        this.formSchema = formSchema;
        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.lift-types.manage',
            'management.lift-types.view',
            'management.lift-types.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    bind() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
        this.schema       = this.formSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.lift-types.inspection-periodicity',
            repository:      {
                search:          (criteria) => this.repository.search(this.liftType.id, criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            destroy:         !this.readonly,
            destroySelected: !this.readonly,
            actions:         [],
            options:         [],
            buttons:         [
                {
                    label:     'form.button.create-new',
                    icon:      'icon-plus3',
                    className: 'btn bg-success',
                    color:     'success',
                    visible:   !this.readonly,
                    action:    () => this.creationSchemaVisible = true,
                },
            ],
            selectable:      true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'building_type',
                    name:  'building_types.name',
                    title: 'form.field.building-type',
                },
                {
                    data:  'periodicity',
                    name:  'inspection_periodicities.periodicity',
                    title: 'form.field.periodicity(years)',
                },
            ],
        };
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.repository
            .create(this.liftType.id, this.model)
            .then(response => this.afterSubmitCallback(response))
            .catch(console.log.bind(console));
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);
            this.datatable.instance.reload();
            this.resetForm();
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues()
            .then(() => {
                // publishes `form-reseted` event
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);

                if (nullifyAlert) {
                    this.alert = null;
                }
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
            this.model.assign(this.initialModel);

            resolve(true);
            reject(new Error('Error'));
        });
    }

}
