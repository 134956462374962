import { inject }                    from 'aurelia-framework';
import { BuildingType }              from 'modules/management/models/building-type';
import { BooleanStatus }             from 'modules/administration/models/boolean-status';
import { BooleanStatusesRepository } from 'modules/administration/boolean-statuses/services/repository';

@inject(BooleanStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{status_id: number}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param booleanStatusesRepository
     */
    constructor(booleanStatusesRepository) {
        this.booleanStatusesRepository = booleanStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {BuildingType}
     */
    model() {
        let model = new BuildingType();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       12,
            attributes: {
                readonly: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            options:      [],
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     (event) => viewModel.redirectToRoute('management.building-types.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.name],
            [this.status_id],
            [this.buttons],
        ];
    }

}
