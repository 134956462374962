import { FilesRepository } from 'modules/administration/files/services/repository';

export class ClauseFilesRepository extends FilesRepository {

    baseUri = 'management/clauses/';

    /**
     * Downloads the upload template file
     *
     * @returns {*}
     */
    downloadTemplate() {
        return this.httpClient.rawGet(`${this.baseUri}files/download-template`)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

}
