import { DialogController }                     from 'aurelia-dialog';
import { bindable, inject }                     from 'aurelia-framework';
import { BaseFormViewModel }                    from 'base-form-view-model';
import { FormSchema }                           from 'modules/management/clauses/import/form-schema';
import { ClauseFilesRepository }                from 'modules/management/clauses/services/files-repository';
import { ClausesRepository }                    from 'modules/management/clauses/services/repository';
import { AppContainer }                         from 'resources/services/app-container';
import { Downloader }                           from 'resources/services/downloader';
import { UnorderedListFromArrayValueConverter } from 'resources/value-converters/text-converters';

@inject(AppContainer, ClausesRepository, ClauseFilesRepository, DialogController, Downloader, FormSchema, UnorderedListFromArrayValueConverter)
export class ImportClauses extends BaseFormViewModel {

    @bindable formId = 'management-clauses-import-form';

    @bindable alert;
    @bindable settings = {
        title:   'form.title.import-records',
        size:    'modal-md',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filesRepository
     * @param dialogController
     * @param formSchema
     * @param downloader
     * @param unorderedListFromArrayValueConverter
     */
    constructor(appContainer, repository, filesRepository, dialogController, downloader, formSchema, unorderedListFromArrayValueConverter) {
        super(appContainer);

        this.repository                   = repository;
        this.filesRepository              = filesRepository;
        this.dialogController             = dialogController;
        this.formSchema                   = formSchema;
        this.downloader                   = downloader;
        this.createUnorderedListFromArray = unorderedListFromArrayValueConverter;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.clauses.manage',
            'management.clauses.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema         = this.formSchema.schema(this);
            this.templateSchema = this.formSchema.templateSchema(this);
            this.buttonsSchema  = this.formSchema.buttonsSchema(this);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.repository
            .import(this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => response.status === true ? this.dialogController.ok() : false);
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            let rowsErrors = { ...response };
            delete rowsErrors.status;
            delete rowsErrors.message;

            this.alert = this.alertMessage(response.status, response.message, '<li><a href="' + rowsErrors[0] + '" class="btn btn-outline-danger"><i class="icon-file-download2" ></i> Ficheiro importado com erros </a></li>');
        }

        return response;
    }

    /**
     * Downloads the upload file template
     */
    downloadTemplate() {
        this.filesRepository.downloadTemplate().then(response => this.downloader.download(response, 'clausulas', 'xlsx'));
    }

}
